<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';

export default {
  name: 'MobileUsersByDatabasesFullReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'MobileUsersByDatabasesFullReport',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Отчет по мобильным пользователям сводный',
        },
      ],
    };
  },
};
</script>
